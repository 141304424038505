export const isNotDev = process.env.REACT_APP_ENV !== 'development';
const DOMAIN_URL = isNotDev
  ? (() => {
      // assuming we'll be running content and course servers as subdomains
      const { host } = window.location;
      const hostComponents = host.split('.');
      hostComponents.shift();
      return hostComponents.join('.');
    })()
  : 'dev.univ.ai';

const PROTOCOL = isNotDev ? 'https' : 'http';

export const APP_BASEPATH = isNotDev ? process.env.PUBLIC_URL : window.location.origin;
export const APP_BASENAME = isNotDev ? process.env.REACT_APP_BASENAME : '';
export const COURSE_URL = process.env.REACT_APP_COURSE_URL;
export const STATIC_URL = 'https://static.univ.ai/public';
// export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const COURSE_API_URL = `${COURSE_URL}/api`;
export const FEATURED_PRODUCTS = process.env.REACT_APP_FEATURED_PRODUCTS;

export const CERTIFICATE_URL = `${PROTOCOL}://certificate.${DOMAIN_URL}/c`;
export const AUTH_URL = `${PROTOCOL}://user.${DOMAIN_URL}`;
export const TEST_URL = `${PROTOCOL}://test.${DOMAIN_URL}/app/exercise`;
export const MY_STUFF_URL = `${PROTOCOL}://my.${DOMAIN_URL}`;
export const APPLICATION_SITE_URL = `${PROTOCOL}://application.${DOMAIN_URL}`;
export const EXERCISE_API_URL = `${PROTOCOL}://api.exercise.${DOMAIN_URL}`;
export const HELP_PATH = '/help';
export const REGISTRATION_PATH = '/register';
export const LOGIN_PATH = '/login';
export const LOGIN_MAIL_PATH = '/login/mail';
export const USER_PROFILE_PATH = '/profile';
export const PAYMENT_PATH = '/payment';
export const FEATURED_PATH = '/featured';
export const INSTRUCTIONS_PATH = '/instructions';
export const PAYMENT_SUCCESS_PATH = `${PAYMENT_PATH}/success`;
export const USER_APPLICATION_PATH = '/applications';
export const USER_ACTIVE_APPLICATION_PATH = '/applications/latest';
export const USER_APPLICATION_SUCCESS_PATH = `${USER_APPLICATION_PATH}/success`;
export const APPLICATION_QUESTIONNAIRE_PATH = `${USER_APPLICATION_PATH}/questionnaire`;
export const APPLICATION_QUESTIONNAIRE_SUCCESS_PATH = `${USER_APPLICATION_PATH}/questionnaire/success`;
export const APPLICATION_TEST_PATH = `${USER_APPLICATION_PATH}/test`;
export const APPLICATION_TEST_INITIALIZE_PATH = `${USER_APPLICATION_PATH}/test/initialize`;
export const CERTIFICATE_PATH = '/c';
export const CERTIFICATES_PATH = '/certificates';
export const APPLICATION_ADMISSION_FORM_PATH = `${USER_APPLICATION_PATH}/admission-form`;

export const USER_URL = `${PROTOCOL}://user.${DOMAIN_URL}`;
export const PROFILE_URL = `${USER_URL}/profile`;

export const REGISTRATION_URL = `${AUTH_URL}${REGISTRATION_PATH}`;
export const AUTH_EMAIL_URL = `${AUTH_URL}/otp/verify`;
export const AUTH_OTP_URL = `${AUTH_URL}/otp`;
export const getAuthGithubURL = (fromURL: string) =>
  `${AUTH_URL}/authenticate/github?success=${fromURL}&newuser=${APP_BASEPATH}${REGISTRATION_PATH}?from=${fromURL}&invalid=${APP_BASEPATH}${LOGIN_MAIL_PATH}`;
export const getAuthGoogleURL = (fromURL: string) =>
  `${AUTH_URL}/authenticate/google?success=${fromURL}&newuser=${APP_BASEPATH}${REGISTRATION_PATH}?from=${fromURL}&invalid=${APP_BASEPATH}${LOGIN_MAIL_PATH}`;
export const getLogoutURL = (redirectURL?: string) =>
  `${AUTH_URL}/logout${redirectURL ? `?redirect=${redirectURL}` : ''}`;
export const getAuthRefreshURL = (fromURL: string) => `${AUTH_URL}/jwt/refresh?from=${fromURL}`;

export const USER_PROFILE_URL = `${AUTH_URL}/user`;
export const USER_PROFILE_CREATE_URL = `${USER_PROFILE_URL}/create`;

export const PRODUCT_LIST_URL = `${COURSE_API_URL}/products`;
export const USER_COURSES_URL = `${COURSE_API_URL}/user/courses`;
export const PAYMENT_RATE_URL = `${COURSE_API_URL}/payment`;
export const STRIPE_PAYMENT_URL = `${COURSE_API_URL}/payment/stripe`;
export const ZEST_PAYMENT_URL = `${COURSE_API_URL}/payment/zest`;
export const RAZOR_PAYMENT_URL = `${COURSE_API_URL}/payment/razor`;
export const RECURRING_PAYMENT_URL = `${COURSE_API_URL}/payment/recurring`;
export const SUBSCRIPTION_PAYMENT_URL = `${COURSE_API_URL}/payment/subscription`;
export const USER_OVERVIEW_URL = `${COURSE_API_URL}/user/overview`;
export const CERTIFICATE_CHECK_URL = `${COURSE_API_URL}/certificate`;
export const CERTIFICATE_LIST_URL = `${COURSE_API_URL}/certificates`;
export const APPLICATIONS_URL = `${COURSE_API_URL}/applications`;
export const REFERRAL_URL = `${COURSE_API_URL}/referral`;
export const APPLY_REFERRAL_URL = `${COURSE_API_URL}/referral/apply`;
export const UPLOAD_URL = `${COURSE_API_URL}/application/upload`;
export const SAVE_INTERESTED_DOMAIN_URL = `${COURSE_API_URL}/save-tracker`;
export const UPDATE_INTERESTED_DOMAIN_URL = `${COURSE_API_URL}/update-tracker`;
export const INVALID_TOKEN_MSG = 'Invalid token. Access denied.';
export const ACCESS_DENIED_MSG = 'Access denied.';
export const NO_CONTENT = 'No Content.';
export const SEND_OTP = `${USER_URL}/send-otp`;
export const VERIFY_OTP = `${USER_URL}/verify-otp`;
export const COUNTRY_CODE_URL = `${COURSE_API_URL}/country-code`;

export const APPLICATION_EVENTS = [
  {
    event: 'questionnaire_submit',
    getURL: ({
      applicationId,
      questionnaireId,
      productId,
    }: {
      applicationId?: any;
      questionnaireId?: any;
      productId?: any;
    }) =>
      `${APP_BASEPATH}${APPLICATION_QUESTIONNAIRE_PATH}?questionnaireId=${questionnaireId}&applicationId=${applicationId}&productId=${productId}`,
  },
  {
    event: 'profile_complete',
    getURL: ({
      applicationId,
      context,
      productId,
    }: {
      applicationId?: any;
      context?: any;
      questionnaireId?: any;
      productId?: any;
    }) =>
      `${APP_BASEPATH}${APPLICATION_ADMISSION_FORM_PATH}?context=${context}&applicationId=${applicationId}&productId=${productId}`,
  },
  {
    event: 'test_submit',
    getURL: ({
      applicationId,
      productId,
      testId,
      from,
      version,
    }: {
      applicationId?: any;
      productId?: any;
      testId?: any;
      from?: any;
      version?: any;
    }) =>
      version === 2
        ? `${APP_BASEPATH}${APPLICATION_TEST_INITIALIZE_PATH}?testId=${testId}&applicationId=${applicationId}`
        : `${TEST_URL}/${testId}?applicationId=${applicationId}&productId=${productId}&from=${encodeURIComponent(
            from
          )}`,
  },
  { event: 'approval', getURL: () => null },
  { event: 'interview', getURL: () => null },
  { event: 'admittance', getURL: () => null },
  { event: 'payment_success', getURL: () => null },
  { event: '', getURL: () => null },
];

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const FINANCING_MINIMUM_VALUE = 100000;
export const STRIPE = 'Stripe';
// export const ZEST = 'ZestMoney';
export const RAZOR = 'RazorPay';
export const RAZORSUB = 'RazorPay Subscription';
export const RAZORSUBFAKE = 'RazorPay Recurring';
export const ISA = 'Income Sharing Agreement';
